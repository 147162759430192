/*
#e93c3c
*/
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;400;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Changa:wght@700&display=swap');
html,body{
    background:#1f232d;
    font-size:14px;
    font-family: 'Noto Sans KR', sans-serif;
}

html{
    overflow-y: scroll;
}

a{
    color: #fff;
    text-decoration: none;
}



article{
    h2{
        color:#fff6de;
        font-weight: 100;
        font-size:3rem;
        line-height: 3.9rem;
        letter-spacing: -1px;
    }
    p{
        color:#bfc8df;
        line-height: 1.6rem;
        font-size:1.1rem;
    }
}

header{

    h1{
        color:#cab887;
        font-weight: 400;
        font-size:1.6rem;
        margin:0;
        &:after{
            content:"";
            display: inline-block;
            width: 100%;
            height: 2px;
            max-width: 150px;
            margin: 0 0 0 10px;
            background-color: #cab887;
        }
    }
}

.engFont{
    font-family: 'Changa', sans-serif;
}